import { useParams } from "react-router-dom";

import TradingViewWidget from "react-tradingview-widget";
import { Routes, Route, Link } from "react-router-dom";
import './App.css';
import Trading from "./trading";

function App() {
 


 

  return (
<Routes>
  <Route path="/:id" element={<Trading pair={window.location.pathname.replace("/","")}/>}/>
</Routes>
  );
}

export default App;
